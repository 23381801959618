@font-face {
  font-display: block;
  font-family: Roboto;
  src: url(https://assets.sendinblue.com/font/Roboto/Latin/normal/normal/7529907e9eaf8ebb5220c5f9850e3811.woff2)
      format('woff2'),
    url(https://assets.sendinblue.com/font/Roboto/Latin/normal/normal/25c678feafdc175a70922a116c9be3e7.woff)
      format('woff');
}

@font-face {
  font-display: fallback;
  font-family: Roboto;
  font-weight: 600;
  src: url(https://assets.sendinblue.com/font/Roboto/Latin/medium/normal/6e9caeeafb1f3491be3e32744bc30440.woff2)
      format('woff2'),
    url(https://assets.sendinblue.com/font/Roboto/Latin/medium/normal/71501f0d8d5aa95960f6475d5487d4c2.woff)
      format('woff');
}

@font-face {
  font-display: fallback;
  font-family: Roboto;
  font-weight: 700;
  src: url(https://assets.sendinblue.com/font/Roboto/Latin/bold/normal/3ef7cf158f310cf752d5ad08cd0e7e60.woff2)
      format('woff2'),
    url(https://assets.sendinblue.com/font/Roboto/Latin/bold/normal/ece3a1d82f18b60bcce0211725c476aa.woff)
      format('woff');
}

#sib-container input:-ms-input-placeholder {
  text-align: left;
  font-family: 'Helvetica', sans-serif;
  color: #c0ccda;
  border-width: px;
}

#sib-container input::placeholder {
  text-align: left;
  font-family: 'Helvetica', sans-serif;
  color: #c0ccda;
  border-width: px;
}

@import url('https://assets.sendinblue.com/component/form/2ef8d8058c0694a305b0.css');
@import url('https://assets.sendinblue.com/component/clickable/b056d6397f4ba3108595.css');
@import url('https://assets.sendinblue.com/component/progress-indicator/f86d65a4a9331c5e2851.css');
@import url('https://sibforms.com/forms/end-form/build/sib-styles.css');
@import url('https://fonts.googleapis.com/css?family=Roboto');
