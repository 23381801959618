$primaryBlue: #82c9ed;
$secondaryBlue: #3b83a8;
$secondaryBlack: #1c1c1c;
$secondaryGrey: #e9e9e9;
$primaryFont: "Source Sans Pro", sans-serif;
$seconaryFont: "Press Start 2P", cursive;
$white: #fff;
$primaryGrey: #f5f5f5;

body {
  font-family: $primaryFont;
}
.navbar-brand {
  font-family: $seconaryFont;
  color: $secondaryBlack;
}

.navbar-brand a {
  color: $secondaryBlack;
}
.navbar-brand a:hover {
  color: $secondaryBlack;
}
.nav-link {
  font-family: $primaryFont;
  font-size: 16px;
  color: $secondaryBlack;
  font-weight: 400;
  border-bottom: solid 1px transparent;
  margin-left: 2px;
  margin-right: 2px;
}
.nav-item .active {
  color: $primaryBlue;
}
.navbar-light .navbar-nav .nav-link.active {
  color: $primaryBlue;
}

@media (min-width: 600px) {
  .nav-item .active {
    border-bottom-color: $primaryBlue;
  }
  .navbar-light .navbar-nav .nav-link.active {
    border-bottom-color: $primaryBlue;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
  .nav-link:hover {
    border-bottom-color: $primaryBlue;
  }
}

.nav-item:hover .nav-link:hover {
  color: $primaryBlue;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $primaryFont;
  font-weight: 100 !important;
}

.section-header {
  font-weight: 200;
  padding-top: 10px;
}

.section-header:after {
  content: "";
  display: flex;
  width: 72px;
  height: 1px;
  background-color: $secondaryBlack;
  margin: 0 auto;
  margin-top: 10px;
}

.section-lead {
  font-size: 16px;
  font-weight: 200;
}

.service-header {
  font-size: 16px;
  font-weight: 700;
  color: $secondaryBlack;
}

.service-box {
  height: 150px;
  width: 150px;
  background-color: $secondaryGrey;
  border-color: $secondaryGrey;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-box:hover {
  background-color: $white;
  border-color: $secondaryBlack;
  border-width: 1px;
  border-style: solid;
}

.service-text {
  font-size: 14px;
  font-weight: 400;
  font-family: $primaryFont;
}

.section-white {
  background-color: $white;
}

.section-grey {
  background-color: $secondaryGrey;
}

.section-blue {
  background-color: $primaryBlue;
}

.section-blue .section-header {
  color: $white;
}
.section-blue .section-header:after {
  background-color: $white;
}

.carousel-caption h3 {
  font-family: $seconaryFont;
  font-size: 26px;
  margin-top: -120px;
}

.carousel-caption p {
  font-family: $primaryFont;
  font-size: 20px;
}

input,
textarea {
  font-family: $primaryFont;
}

.input-group {
  margin-bottom: 15px;
}
.input-group-prepend {
  background-color: $white;
}
.input-group-text {
  background-color: $white;
  color: $secondaryBlue;
  align-items: flex-start;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: $secondaryBlue !important;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  /* Firefox 18- */
  color: $secondaryBlue !important;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: $secondaryBlue !important;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: $secondaryBlue !important;
}

textarea {
  resize: none;
}

.form-button {
  background-color: $primaryGrey;
  color: $secondaryBlue;
  border-radius: 0;
  border-color: $secondaryBlue;
  font-family: $primaryFont;
}

.form-button:hover {
  background-color: $secondaryBlue;
  color: $primaryGrey;
  border-radius: 0;
  border-color: $primaryGrey;
}

.form-button:active {
  background-color: $secondaryBlue;
  color: $primaryGrey;
  border-radius: 0;
  border-color: $primaryGrey;
}

.client img {
  height: 80px;
  max-width: 150px;
}

.client img:hover {
  max-width: 150px;
}

.member-name {
  font-weight: 700;
}

.member-social-icon-container {
  height: 36px;
  width: 36px;
  background-color: $secondaryBlue;
  border-color: $secondaryBlue;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
}

.member-social-icon-container:hover {
  background-color: $primaryBlue;
  border-color: $secondaryGrey;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
}
.member-social-icon {
  color: $secondaryGrey;
}

/* 
Forces carousel image to be 100% width and not max width of 100% 
*/
.carousel-item img {
  width: 100%;
  height: 100%;
} /*
Forces carousel image to be 100% width and not max width of 100%
*/
.carousel-item img {
  width: 100%;
  height: 100%;
}
.page-container {
  margin-top: 60px;
}

.class-thumbnail-img {
  max-width: 100%;
  max-height: 100%;
}
.img-col {
  max-width: 300px;
  margin-right: 10px;
  flex: 1;
}

.desc-col {
  display: flex;
  flex-direction: column;
  flex: 2;
}

.class-title {
  font-weight: 700;
  font-size: larger;
}

.class-header-row {
  display: flex;
  justify-content: space-between;
}

.class-info-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
  font-size: 18px;
}

.class-cost {
  text-decoration: none;
  color: $secondaryBlue;
  font-size: 20px;
  display: flex;
  align-items: flex;
  justify-content: center;
  margin-right: 15px;
  font-weight: 700;
}
.class-type {
  text-decoration: none;
}
.class-level {
  text-decoration: none;
}
.class-info-col {
  display: flex;
  flex-direction: column;
}

.class-description-row {
  margin-top: 10px;
}
.class-register-row {
  margin-top: 10px;
}

.divider {
  margin: 5px;

  border-left: 1px solid $secondaryBlack;
}

.btn-primary {
  color: #fff;
  background-color: $secondaryBlue;
  border-color: $secondaryBlue;
}

.btn-primary:hover {
  color: #fff;
  background-color: $primaryBlue;
  border-color: $primaryBlue;
}
